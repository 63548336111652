import React, {useContext, useEffect} from "react"
import {navigate} from "gatsby"
import AuthContext from "../../components/Context/AuthContext";
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import AccountMenu from "../../components/AccountMenu"
import PageTitle from "../../components/PageTitle";
import {ButtonTop} from "../../components/Button";
import PageNavigation from "../../components/PageNavigation";

export default () => {
  const {session} = useContext(AuthContext)

  useEffect(() => {
    if (session === undefined) {
      navigate("/login/")
    }
  }, [session])

  return (
    <Layout>
      <SEO title="アカウント管理"/>
      <section className="inner">
        <PageTitle>アカウント管理</PageTitle>
        <AccountMenu/>
        <PageNavigation>
          <ButtonTop/>
        </PageNavigation>
      </section>
    </Layout>
  )
}