import React from "react"
import {navigate} from "gatsby";
import {ButtonMenu, ButtonLink} from "../Button";
import classNames from "classnames";

export default () => (
  <div className={classNames("container", "bg-white", "inner")}>
    <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
      <ButtonMenu click={() => navigate("/account/summary/")}>ご注文者情報の表示</ButtonMenu>
      <ButtonLink href={`${process.env.MODD_CART_URL}member/OrderList.aspx?ccode=${process.env.MODD_CART_ID}`}>注文履歴</ButtonLink>
      <ButtonLink href={`${process.env.MODD_CART_URL}member/CustCard.aspx?ccode=${process.env.MODD_CART_ID}`}>クレジットカード登録変更</ButtonLink>
      <ButtonLink href={`${process.env.MODD_CART_URL}member/PreOrderList.aspx?ccode=${process.env.MODD_CART_ID}`}>受注商品一覧</ButtonLink>
    </div>
  </div>
)
